.App {
  text-align: center;
  background-color: #343334;
  padding-bottom: 2em;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  box-shadow: #000000 4px 4px 8px, #4d4d4d -4px -4px 8px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.globalStat {
  color: aliceblue;
  font-size: large;
  display: flex;
  text-align: center;
  margin: auto;
  flex-direction: column;
  border-radius: 1em;
  margin: 1em;
  background-color: #444444;
}

.indStat {
  color: aliceblue;
  font-size: large;
  display: flex;
  text-align: center;
  margin: auto;
  flex-direction: column;
  border-radius: 1em;
  margin: 1em;
  background-color: #444444;
}
.stateIndStat {
  color: aliceblue;
  font-size: large;
  display: flex;
  text-align: center;
  margin: auto;
  flex-direction: column;
  border-radius: 1em;
  margin-top: 1em;
  margin-left: 1em;
  margin-right: 1em;
  background-color: #444444;
}
.head {
  margin-left: 1em;
  margin-right: 1em;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}
.total {
  margin-left: 1em;
  margin-right: 1em;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}

.confirm {
  margin-left: 1em;
  margin-right: 1em;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}

.recovered {
  margin-left: 1em;
  margin-right: 1em;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}

.death {
  margin-left: 1em;
  margin-right: 1em;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}

.loading {
  text-align: center;
  /* font: 300 4em/150% Impact; */
}

.loading:after {
  content: " .";
  animation: dots 1s steps(5, end) infinite;
}

@keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: black;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 black, 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 black, 0.5em 0 0 black;
  }
}
